<template>
    <div>

        <iframe  :src="this.$route.query.URL" id="frameId" frameborder="0" :width="VRwidth" :height="VRheight"
                ></iframe>
        <div class="header">
            <van-button type="info" color="#3F7C53" @click="backClick">返回</van-button>
        </div>
    </div>
</template>

<script>

    export default {
        name: "imcroframe",
        data(){
            return {
                VRwidth: document.body.clientWidth ,
                VRheight: document.body.clientHeight+30
            }
        },
        mounted() {
            //this.url = this.$route.query.URL
            //uwStatisticAction('/VRIframe','灵活用工签约')
        },
        methods: {
            backClick() {
                this.$router.push({name: 'allincome'})
            },
        }
    }
</script>

<style scoped>
    .back{
        background: #3F7C53 !important;
        color: #fff !important;
        text-align: center;
    }
    .footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    .van-button{
        width: 100%;
    }
    }
    .header{
        text-align: right;
        bottom: 0;
    }
</style>